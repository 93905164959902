import BaseScreen from "layouts/base";
import React from "react";
import { Link } from "react-router-dom";
import "./dashboard.scss";
import { Subscription } from "rxjs";
import { ToolfinderSelectionsState, ToolfinderSelectionsUpdated } from "../blocs/toolfiinder_selections_bloc/toolfiinder_selections_state";
import ToolfinderSelection from "models/toolfinder_selection";
import { BlocsContext } from "contexts/blocs_context";
import { ToolfinderResetSelections, ToolfinderSetCutParams, ToolfinderSetSelections } from "../blocs/toolfiinder_selections_bloc/toolfiinder_selections_event";
import ToolfinderCutParams from "models/toolfinder_cut_params";
import { CutParamsState, CutParamsUpdated } from "../blocs/cut_params_bloc/cut_params_state";
import { CutParamsReset, CutParamsSetCutParam, CutParamsSetCutParams, CutParamsSetToolfinderSelection } from "../blocs/cut_params_bloc/cut_params_event";
import { WithTranslation, withTranslation } from "react-i18next";
import { CutParam, CUT_PARAM_TYPE, FRAISAGE_FIELDS, PERCAGE_FIELDS } from "constants/constants";

// import 'bootstrap/dist/css/bootstrap.min.css';

import toast, { Toaster } from "react-hot-toast";
import GeneralHelper from "helpers/general_helper";
import ExportModal from "screens/export_modal/export_modal";

import { isMobile } from "react-device-detect";

import MatiereIcon from "assets/icons/matiere.svg";
import UsinageIcon from "assets/icons/usinage.svg";
import RefOutilIcon from "assets/icons/ref_outil.svg";
import RevetementIcon from "assets/icons/revetement.svg";
import MachineIcon from "assets/icons/machine.svg";
import CutParamsItem from "./components/cut_params_item";
import SelectionItem from "./components/selection_item";
import ShoppingCartIcon from "assets/icons/shopping-cart.svg";
import { BlocBuilder } from "@felangel/react-bloc";
import AddToCartButton from "components/add_to_cart_button";
import AddToFavoriteButton from "components/add_to_favorite_button";
import NewsCarousel from "./components/news_carousel";
import Message from "models/message";
import { MessagesLoaded, MessagesState } from "blocs/messages_bloc/messages_state";
import moment from "moment";

interface Props extends WithTranslation { }

type State = {
  // Vc: string;
  // n: string;
  // D: string;
  // Z: string;
  // Fz: string;
  // Vf: string;
  // ap: string;
  // ae: string;
  // Q: string;
  // RM: string;
  // eta: string;
  // P: string;
  // hm: string;
  // Emax: string;
  // LgCop: string;
  // VolCop: string;
  // Vctf: string;

  cutParamsValue: any;
  cutParamsUpdateIncrement: number;
  toolfinderSelectionUpdateIncrement: number;

  toolfinderSelection: ToolfinderSelection;
  toolfinderCutParams: ToolfinderCutParams;

  exportModaleOpened: boolean;
  downloadMobileAppPopupClosed: boolean;
};

class DashboardScreen extends React.Component<Props, State> {
  static contextType = BlocsContext;
  context!: React.ContextType<typeof BlocsContext>;

  private toolfinderSelectionBlocSubscription: Subscription = Subscription.EMPTY;
  private cutParamsBlocSubscription: Subscription = Subscription.EMPTY;

  currentEditing!: String;

  constructor(props: Props) {
    super(props);

    var downloadMobileAppPopupClosed = localStorage.getItem("downloadMobileAppPopupClosed") == "true" ? true : false;

    var toolfinderCutParams: ToolfinderCutParams = this.context?.cutParamsBloc?.toolfinderCutParams ?? new ToolfinderCutParams({});
    this.state = {
      cutParamsValue: {
        FRAISAGE_Vc: "0.0",
        FRAISAGE_n: "0.0",
        FRAISAGE_d1: "0.0",
        FRAISAGE_Z: "0.0",
        FRAISAGE_Fz: "0.0",
        FRAISAGE_Vf: "0.0",
        FRAISAGE_ap: "0.0",
        FRAISAGE_ae: "0.0",
        FRAISAGE_Q: "0.0",
        FRAISAGE_hm: "0.0",
        FRAISAGE_Emax: "0.0",
        PERCAGE_Vc: "0.0",
        PERCAGE_n: "0.0",
        PERCAGE_d1: "0.0",
        PERCAGE_Z: "0.0",
        PERCAGE_Q: "0.0",
        PERCAGE_F: "0.0",
        PERCAGE_Vf: "0.0",

        // FRAISAGE_Vc: toolfinderCutParams.FRAISAGE_Vc ?? 0,
        // FRAISAGE_n: toolfinderCutParams.FRAISAGE_n ?? 0,
        // FRAISAGE_d1: toolfinderCutParams.FRAISAGE_d1 ?? 0,
        // FRAISAGE_Z: toolfinderCutParams.FRAISAGE_Z ?? 0,
        // FRAISAGE_Fz: toolfinderCutParams.FRAISAGE_Fz ?? 0,
        // FRAISAGE_Vf: toolfinderCutParams.FRAISAGE_Vf ?? 0,
        // FRAISAGE_ap: toolfinderCutParams.FRAISAGE_ap ?? 0,
        // FRAISAGE_ae: toolfinderCutParams.FRAISAGE_ae ?? 0,
        // FRAISAGE_Q: toolfinderCutParams.FRAISAGE_Q ?? 0,
        // FRAISAGE_hm: toolfinderCutParams.FRAISAGE_hm ?? 0,
        // FRAISAGE_Emax: toolfinderCutParams.FRAISAGE_Emax ?? 0,
        // PERCAGE_Vc: toolfinderCutParams.PERCAGE_Vc ?? 0,
        // PERCAGE_n: toolfinderCutParams.PERCAGE_n ?? 0,
        // PERCAGE_d1: toolfinderCutParams.PERCAGE_d1 ?? 0,
        // PERCAGE_Z: toolfinderCutParams.PERCAGE_Z ?? 0,
        // PERCAGE_Q: toolfinderCutParams.PERCAGE_Q ?? 0,
        // PERCAGE_F: toolfinderCutParams.PERCAGE_F ?? 0,
        // PERCAGE_Vf: toolfinderCutParams.PERCAGE_Vf ?? 0,
      },
      cutParamsUpdateIncrement: 0,
      toolfinderSelectionUpdateIncrement: 0,
      toolfinderSelection: new ToolfinderSelection({}),
      toolfinderCutParams: new ToolfinderCutParams({}),

      exportModaleOpened: false,
      downloadMobileAppPopupClosed: downloadMobileAppPopupClosed,
    };
  }

  closeDownloadMobileAppPopup = () => {
    this.setState({
      downloadMobileAppPopupClosed: true,
    });
    localStorage.setItem("downloadMobileAppPopupClosed", "true");
  };

  componentDidMount(): void {
    this.toolfinderSelectionBlocSubscription = this.context.toolfinderSelectionBloc.listen(this.toolfinderSelectionBlocAction);
    this.toolfinderSelectionBlocAction(this.context.toolfinderSelectionBloc.state);

    this.cutParamsBlocSubscription = this.context.cutParamsBloc.listen(this.cutParamsBlocAction);
    this.cutParamsBlocAction(this.context.cutParamsBloc.state);
  }

  componentWillUnmount(): void {
    this.toolfinderSelectionBlocSubscription.unsubscribe();
    this.cutParamsBlocSubscription.unsubscribe();
  }

  toolfinderSelectionBlocAction = (state: ToolfinderSelectionsState) => {
    if (state instanceof ToolfinderSelectionsUpdated) {
      this.setState({
        toolfinderSelection: state.toolfinderSelection,
        toolfinderSelectionUpdateIncrement: +this.state.toolfinderSelectionUpdateIncrement + 1,
      });

      if (this.context.cutParamsBloc && state.changedCutParam == undefined) {
        this.context.cutParamsBloc.add(new CutParamsSetToolfinderSelection(state.toolfinderSelection));
      }
    }
  };

  cutParamsBlocAction = (state: CutParamsState) => {
    if (state instanceof CutParamsUpdated) {
      var cutParamsValue: any = this.state.cutParamsValue;

      var fields: Map<CUT_PARAM_TYPE, CutParam> = FRAISAGE_FIELDS;

      if (this.state.toolfinderSelection.machining?.usiGrpId == 100000) {
        fields = FRAISAGE_FIELDS;
      } else if (this.state.toolfinderSelection.machining?.usiGrpId == 100001) {
        fields = PERCAGE_FIELDS;
      } else {
        fields = FRAISAGE_FIELDS;
      }

      fields.forEach((value, key) => {
        if (state.currentEditing !== key) {
          var cutParamValue = state.toolfinderCutParams[this.getKeyFromCutParam(key)] as number;
          if (cutParamValue != undefined) {
            cutParamsValue[this.getKeyFromCutParam(key)] = cutParamValue.toFixed(value.decimalLength);
          } else {
            cutParamsValue[this.getKeyFromCutParam(key)] = "";
          }
        }
      });

      if (this.context.toolfinderSelectionBloc && state.currentEditing != undefined) {
        this.context.toolfinderSelectionBloc.add(new ToolfinderSetCutParams(state.toolfinderCutParams));
      }

      this.setState({
        toolfinderCutParams: state.toolfinderCutParams,

        cutParamsValue: cutParamsValue,
        cutParamsUpdateIncrement: +this.state.cutParamsUpdateIncrement + 1,
      });
    }
  };

  handleChange = (cutParamType: CUT_PARAM_TYPE, event: React.FormEvent<HTMLInputElement>) => {
    // this.setState({ ...this.state, [inputName]: event.currentTarget.value });
    var value: string = event.currentTarget.value;
    if (parseFloat(value) == undefined) {
      return;
    }
    var cutParamsValue: any = this.state.cutParamsValue;

    cutParamsValue[this.getKeyFromCutParam(cutParamType)] = value;

    this.setState({ cutParamsValue: cutParamsValue });

    var parsedValue = Number.parseFloat(event.currentTarget.value);
    if (parsedValue != undefined && Number.isNaN(parsedValue) == false) this.context.cutParamsBloc.add(new CutParamsSetCutParam({ cutParamType: cutParamType, value: parsedValue }));
  };

  handleSelectionLockSwitchToggle = (lockName: string, event: React.FormEvent<HTMLInputElement>) => {
    this.context.toolfinderSelectionBloc.add(
      new ToolfinderSetSelections(
        (this.context.toolfinderSelectionBloc.state as ToolfinderSelectionsUpdated).toolfinderSelection.copyWith({
          [lockName]: event.currentTarget.checked,
        })
      )
    );

    // this.setState({ ...this.state, [inputName]: event.currentTarget.value });
  };

  handleCutParamsLockSwitchToggle = (lockName: string, event: React.FormEvent<HTMLInputElement>) => {
    var cutParams: ToolfinderCutParams = (this.context.cutParamsBloc.state as CutParamsUpdated).toolfinderCutParams;

    // cutParams.copyWith({
    // 	[lockName]: event.currentTarget.checked,
    // });

    if (lockName === "FRAISAGE_Vc_Locked" && event.currentTarget.checked === true && cutParams.FRAISAGE_n_Locked === true) {
      GeneralHelper.warningToast(this.props.t("screen.dashboard.warningCutParamsSwitch1"));
      cutParams.FRAISAGE_n_Locked = false;
    } else if (lockName === "FRAISAGE_n_Locked" && event.currentTarget.checked === true && cutParams.FRAISAGE_Vc_Locked === true) {
      GeneralHelper.warningToast(this.props.t("screen.dashboard.warningCutParamsSwitch2"));
      cutParams.FRAISAGE_Vc_Locked = false;
    } else if (lockName === "FRAISAGE_Fz_Locked" && event.currentTarget.checked === true && cutParams.FRAISAGE_Vf_Locked === true) {
      GeneralHelper.warningToast(this.props.t("screen.dashboard.warningCutParamsSwitch3"));
      cutParams.FRAISAGE_Vf_Locked = false;
    } else if (lockName === "FRAISAGE_Vf_Locked" && event.currentTarget.checked === true && cutParams.FRAISAGE_Fz_Locked === true) {
      GeneralHelper.warningToast(this.props.t("screen.dashboard.warningCutParamsSwitch4"));
      cutParams.FRAISAGE_Fz_Locked = false;
    }
    cutParams = cutParams.copyWith({
      [lockName]: event.currentTarget.checked,
    });

    this.currentEditing = lockName;
    this.context.cutParamsBloc.add(new CutParamsSetCutParams(cutParams));

    // this.setState({ ...this.state, [inputName]: event.currentTarget.value });
  };

  export = () => {
    this.setState({
      exportModaleOpened: true,
    });
  };

  renderActionButtons() {
    return (
      <div>
        <ExportModal modalOpened={this.state.exportModaleOpened} closeModal={() => this.setState({ exportModaleOpened: false })} />
        <button className="btn btn-primary text-white export-button mx-2" onClick={this.export}>
          {this.props.t("screen.dashboard.export_button")} <span className="icon icon-export"></span>
        </button>
      </div>
    );
  }

  renderNews() {
    return (
      <BlocBuilder
        bloc={this.context.messagesBloc}
        builder={(state: MessagesState) => {
          let news: Message[] = [];
  
          if (state instanceof MessagesLoaded) {
            console.log("news");
            console.log(state.messages);
            console.log(state.messages[0].displayTo);
            console.log(state.messages[0].displayTo.isAfter(moment.now()));
            news = state.messages.filter((message) => message.displayFrom.isBefore(moment.now()) && message.displayTo.isAfter(moment.now()));
          }

          if (news.length == 0) {
            return <></>;
          }

          return (
            <div className="row news-section">
              <div className="container col-md-12 d-flex flex-column justify-content-center align-items-center w-full">
                <h3 className="news_title">{this.props.t("screen.dashboard.news_title")}</h3>
                <NewsCarousel news={state instanceof MessagesLoaded ? news : []} />

              </div>
            </div>
          );
        }}
      />
    );
  }

  renderLeftPart() {
    return (
      <div className="col-md-12 col-lg-5 dashboard-selection">
        <div className="row">
          <div className="col-md-12 mb-4">
            <h3>{this.props.t("screen.dashboard.selections_title")}</h3>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-lg-6 col-xl-4 cardColWrapper">
            {/* <div className={"selection-card" + (this.state.toolfinderSelection.materialLock === true ? " locked" : "")}>
	
							<img src={MatiereIcon} />
							<div className={"cardTitle"}>{this.props.t("screen.dashboard.selection_param_material")}</div>
							<div className={this.state.toolfinderSelection.material ? "cardSelection selected" : "cardSelection"}>{this.state.toolfinderSelection.material ? this.state.toolfinderSelection.material.code : this.props.t("screen.dashboard.unspecified")}</div>
							<Link to="/select-material">{this.props.t("screen.dashboard.edit")} →</Link>

							<label className="cl-switch mt-3">
								<span className="label">{this.state.toolfinderSelection.materialLock === true ? <span className="icon icon-padlock"></span> : <span className="icon icon-unlock"></span>}</span>
								<input type="checkbox" checked={this.state.toolfinderSelection.materialLock === true} onChange={(event) => this.handleSelectionLockSwitchToggle("materialLock", event)} />
								<span className="switcher"></span>
							</label>
						</div> */}

            <SelectionItem
              identifier={"material"}
              toolfinderSelectionUpdateIncrement={this.state.toolfinderSelectionUpdateIncrement}
              hasLock={true}
              locked={this.state.toolfinderSelection.materialLock}
              icon={MatiereIcon}
              title={this.props.t("screen.dashboard.selection_param_material")}
              value={this.state.toolfinderSelection.material?.code}
              linkPath={"/select-material"}
              onLockSwitch={(event: React.FormEvent<HTMLInputElement>) => this.handleSelectionLockSwitchToggle("materialLock", event)}
            />
          </div>
          <div className="col-md-6 col-lg-6 col-xl-4 cardColWrapper">
            {/* <div className={"selection-card"}>
							<img src={UsinageIcon} />

							<div className={"cardTitle"}>{this.props.t("screen.dashboard.selection_param_machining")}</div>
							<div className={this.state.toolfinderSelection.machining ? "cardSelection selected" : "cardSelection"}>
								{this.state.toolfinderSelection.machining
									? this.state.toolfinderSelection.machining.longDescription[this.props.i18n.language]
									: this.props.t("screen.dashboard.unspecified")}
							</div>
							<Link to="/select-machining">{this.props.t("screen.dashboard.edit")} →</Link>
						</div> */}

            <SelectionItem
              identifier={"machining"}
              toolfinderSelectionUpdateIncrement={this.state.toolfinderSelectionUpdateIncrement}
              hasLock={false}
              locked={false}
              icon={UsinageIcon}
              title={this.props.t("screen.dashboard.selection_param_machining")}
              value={this.state.toolfinderSelection.machining?.getLongDescription(this.props.i18n.language)}
              linkPath={"/select-machining"}
              onLockSwitch={(event: React.FormEvent<HTMLInputElement>) => { }}
            />

            {/* <SelectionItem icon={UsinageIcon} handleSelectionLockSwitchToggle={this.handleSelectionLockSwitchToggle} material={this.state.toolfinderSelection.material} materialLock={this.state.toolfinderSelection.materialLock} /> */}
          </div>
          <div className="col-md-6 col-lg-6 col-xl-4 cardColWrapper">
            {/* <div className={"selection-card" + (this.state.toolfinderSelection.toolLock === true ? " locked" : "")}>

							<img src={RefOutilIcon} />

							<div className={"cardTitle"}>{this.props.t("screen.dashboard.selection_param_tool")}</div>
							<div className={this.state.toolfinderSelection.article ? "cardSelection selected" : "cardSelection"}>
								{this.state.toolfinderSelection.article
									? this.state.toolfinderSelection.article.code
									: this.props.t("screen.dashboard.unspecified")}
							</div>
							<Link to="/select-tool">{this.props.t("screen.dashboard.edit")} →</Link>

							<label className="cl-switch mt-3">
								<span className="label">
									{this.state.toolfinderSelection.toolLock === true ? (
										<span className="icon icon-padlock"></span>
									) : (
										<span className="icon icon-unlock"></span>
									)}
								</span>
								<input
									type="checkbox"
									checked={this.state.toolfinderSelection.toolLock === true}
									onChange={(event) => this.handleSelectionLockSwitchToggle("toolLock", event)}
								/>
								<span className="switcher"></span>
							</label>
						</div> */}

            <SelectionItem
              identifier={"tool"}
              toolfinderSelectionUpdateIncrement={this.state.toolfinderSelectionUpdateIncrement}
              hasLock={true}
              locked={this.state.toolfinderSelection.toolLock}
              icon={RefOutilIcon}
              title={this.props.t("screen.dashboard.selection_param_tool")}
              value={this.state.toolfinderSelection.article?.code}
              linkPath={"/select-tool"}
              onLockSwitch={(event: React.FormEvent<HTMLInputElement>) => this.handleSelectionLockSwitchToggle("toolLock", event)}
            />
          </div>
          <div className="col-md-6 col-lg-6 col-xl-4 cardColWrapper">
            {/* <div className={"selection-card" + (this.state.toolfinderSelection.coatingLock === true ? " locked" : "")}>
							<img src={RevetementIcon} />
							<div className={"cardTitle"}>{this.props.t("screen.dashboard.selection_param_coating")}</div>
							<div className={this.state.toolfinderSelection.coating ? "cardSelection selected" : "cardSelection"}>
								{this.state.toolfinderSelection.coating
									? this.state.toolfinderSelection.coating.publicName[this.props.i18n.language] +
									  " (" +
									  this.state.toolfinderSelection.coating.code +
									  ")"
									: this.props.t("screen.dashboard.unspecified")}
							</div>
							<Link to="/select-coating">{this.props.t("screen.dashboard.edit")} →</Link>

							<label className="cl-switch mt-3">
								<span className="label">
									{this.state.toolfinderSelection.coatingLock === true ? (
										<span className="icon icon-padlock"></span>
									) : (
										<span className="icon icon-unlock"></span>
									)}
								</span>
								<input
									type="checkbox"
									checked={this.state.toolfinderSelection.coatingLock === true}
									onChange={(event) => this.handleSelectionLockSwitchToggle("coatingLock", event)}
								/>
								<span className="switcher"></span>
							</label>
						</div> */}

            <SelectionItem
              identifier={"coating"}
              toolfinderSelectionUpdateIncrement={this.state.toolfinderSelectionUpdateIncrement}
              hasLock={true}
              locked={this.state.toolfinderSelection.coatingLock}
              icon={RevetementIcon}
              title={this.props.t("screen.dashboard.selection_param_coating")}
              value={this.state.toolfinderSelection.coating != undefined ? this.state.toolfinderSelection.coating?.getPublicName(this.props.i18n.language) + " (" + this.state.toolfinderSelection.coating.code + ")" : ""}
              linkPath={"/select-coating"}
              onLockSwitch={(event: React.FormEvent<HTMLInputElement>) => this.handleSelectionLockSwitchToggle("coatingLock", event)}
            />
          </div>
          <div className="col-md-6 col-lg-6 col-xl-4 cardColWrapper">
            {/* <div className={"selection-card"}>
							<img src={MachineIcon} />
							<div className={"cardTitle"}>{this.props.t("screen.dashboard.selection_param_machine")}</div>
							<div className={this.state.toolfinderSelection.machine ? "cardSelection selected" : "cardSelection"}>
								{this.state.toolfinderSelection.machine
									? this.state.toolfinderSelection.machine.getShortDescription(this.props.i18n.language)
									: this.props.t("screen.dashboard.unspecified")}
							</div>
							<Link to="/select-machine">{this.props.t("screen.dashboard.edit")} →</Link>
						</div> */}

            <SelectionItem
              identifier={"machine"}
              toolfinderSelectionUpdateIncrement={this.state.toolfinderSelectionUpdateIncrement}
              hasLock={false}
              locked={false}
              icon={MachineIcon}
              title={this.props.t("screen.dashboard.selection_param_machine")}
              value={this.state.toolfinderSelection.machine?.getShortDescription(this.props.i18n.language)}
              linkPath={"/select-machine"}
              onLockSwitch={(event: React.FormEvent<HTMLInputElement>) => { }}
            />
          </div>
          {this.state.toolfinderSelection.article != undefined &&
            this.state.toolfinderSelection.coating != undefined &&
            this.state.toolfinderSelection.coating.dCibleMin != undefined &&
            this.state.toolfinderSelection.coating.dCibleMax != undefined &&
            !(this.state.toolfinderSelection.coating.dCibleMin! <= this.state.toolfinderSelection.article.dimD1 &&
              this.state.toolfinderSelection.coating.dCibleMax! >= this.state.toolfinderSelection.article.dimD1) && (
              <div className="alert alert-warning mt-2 ref-warning" role="alert">
                <span className="icon icon-info icon-left"></span>
                {this.props.t("screen.dashboard.info_d1_warning")}
              </div>
            )}
        </div>
      </div>
    );
  }

  getKeyFromCutParam(cutParamType: CUT_PARAM_TYPE) {
    switch (cutParamType) {
      case CUT_PARAM_TYPE.FRAISAGE_Vc:
        return "FRAISAGE_Vc";
        break;
      case CUT_PARAM_TYPE.FRAISAGE_n:
        return "FRAISAGE_n";
        break;
      case CUT_PARAM_TYPE.FRAISAGE_d1:
        return "FRAISAGE_d1";
        break;
      case CUT_PARAM_TYPE.FRAISAGE_Z:
        return "FRAISAGE_Z";
        break;
      case CUT_PARAM_TYPE.FRAISAGE_Fz:
        return "FRAISAGE_Fz";
        break;
      case CUT_PARAM_TYPE.FRAISAGE_Vf:
        return "FRAISAGE_Vf";
        break;
      case CUT_PARAM_TYPE.FRAISAGE_ap:
        return "FRAISAGE_ap";
        break;
      case CUT_PARAM_TYPE.FRAISAGE_ae:
        return "FRAISAGE_ae";
        break;
      case CUT_PARAM_TYPE.FRAISAGE_Q:
        return "FRAISAGE_Q";
        break;
      case CUT_PARAM_TYPE.FRAISAGE_hm:
        return "FRAISAGE_hm";
        break;
      case CUT_PARAM_TYPE.FRAISAGE_Emax:
        return "FRAISAGE_Emax";
        break;
      case CUT_PARAM_TYPE.PERCAGE_Vc:
        return "PERCAGE_Vc";
        break;
      case CUT_PARAM_TYPE.PERCAGE_n:
        return "PERCAGE_n";
        break;
      case CUT_PARAM_TYPE.PERCAGE_d1:
        return "PERCAGE_d1";
        break;
      case CUT_PARAM_TYPE.PERCAGE_Z:
        return "PERCAGE_Z";
        break;
      case CUT_PARAM_TYPE.PERCAGE_Q:
        return "PERCAGE_Q";
        break;
      case CUT_PARAM_TYPE.PERCAGE_F:
        return "PERCAGE_F";
        break;
      case CUT_PARAM_TYPE.PERCAGE_Vf:
        return "PERCAGE_Vf";
        break;
    }
  }

  getLockedKeyFromCutParam(cutParamType: CUT_PARAM_TYPE) {
    switch (cutParamType) {
      case CUT_PARAM_TYPE.FRAISAGE_Vc:
        return "FRAISAGE_Vc_Locked";
        break;
      case CUT_PARAM_TYPE.FRAISAGE_n:
        return "FRAISAGE_n_Locked";
        break;
      case CUT_PARAM_TYPE.FRAISAGE_d1:
        return "FRAISAGE_d1_Locked";
        break;
      case CUT_PARAM_TYPE.FRAISAGE_Z:
        return "FRAISAGE_Z_Locked";
        break;
      case CUT_PARAM_TYPE.FRAISAGE_Fz:
        return "FRAISAGE_Fz_Locked";
        break;
      case CUT_PARAM_TYPE.FRAISAGE_Vf:
        return "FRAISAGE_Vf_Locked";
        break;
      case CUT_PARAM_TYPE.FRAISAGE_ap:
        return "FRAISAGE_ap_Locked";
        break;
      case CUT_PARAM_TYPE.FRAISAGE_ae:
        return "FRAISAGE_ae_Locked";
        break;
      case CUT_PARAM_TYPE.FRAISAGE_Q:
        return "FRAISAGE_Q_Locked";
        break;
      case CUT_PARAM_TYPE.FRAISAGE_hm:
        return "FRAISAGE_hm_Locked";
        break;
      case CUT_PARAM_TYPE.FRAISAGE_Emax:
        return "FRAISAGE_Emax_Locked";
        break;
      case CUT_PARAM_TYPE.PERCAGE_Vc:
        return "PERCAGE_Vc_Locked";
        break;
      case CUT_PARAM_TYPE.PERCAGE_n:
        return "PERCAGE_n_Locked";
        break;
      case CUT_PARAM_TYPE.PERCAGE_d1:
        return "PERCAGE_d1_Locked";
        break;
      case CUT_PARAM_TYPE.PERCAGE_Z:
        return "PERCAGE_Z_Locked";
        break;
      case CUT_PARAM_TYPE.PERCAGE_Q:
        return "PERCAGE_Q_Locked";
        break;
      case CUT_PARAM_TYPE.PERCAGE_F:
        return "PERCAGE_F_Locked";
        break;
      case CUT_PARAM_TYPE.PERCAGE_Vf:
        return "PERCAGE_Vf_Locked";
        break;
    }
  }

  renderCutParamItem = (value: CutParam, key: CUT_PARAM_TYPE) => {
    return (
      <CutParamsItem
        key={key}
        cutParamsUpdateIncrement={this.state.cutParamsUpdateIncrement}
        value={this.state.cutParamsValue[this.getKeyFromCutParam(key)]}
        locked={this.state.toolfinderCutParams[this.getLockedKeyFromCutParam(key)] === true}
        onLockedChange={(event: React.FormEvent<HTMLInputElement>) => this.handleCutParamsLockSwitchToggle(this.getLockedKeyFromCutParam(key), event)}
        valueData={value}
        valueKey={key}
        onChange={(key: CUT_PARAM_TYPE, event: React.FormEvent<HTMLInputElement>) => {
          this.handleChange(key, event);
        }}
      />
    );

    if (value.isEditable)
      return (
        <div className="d-flex justify-content-between dashboard-cut-param">
          <span className="value-title">{value.title}</span>
          <div>
            <div className="inputContainer">
              <input type="text" value={this.state.cutParamsValue[this.getKeyFromCutParam(key)]} onChange={(event) => this.handleChange(key, event)} placeholder="-" />
            </div>

            <span className="value-suffix">{value.suffix}</span>
            {/* <span className="icon icon-padlock"></span> */}
            <label className="cl-switch">
              <span className="label">
                {this.state.toolfinderCutParams[this.getLockedKeyFromCutParam(key)] === true ? <span className="icon icon-padlock"></span> : <span className="icon icon-unlock"></span>}
              </span>
              <input
                type="checkbox"
                checked={this.state.toolfinderCutParams[this.getLockedKeyFromCutParam(key)] === true}
                onChange={(event) => this.handleCutParamsLockSwitchToggle(this.getLockedKeyFromCutParam(key), event)}
              />
              <span className="switcher"></span>
            </label>
          </div>
        </div>
      );

    return (
      <div className="d-flex justify-content-between dashboard-cut-param">
        <span className="value-title">{value.title}</span>
        <div>
          <input className="edit-disabled" type="text" value={this.state.cutParamsValue[this.getKeyFromCutParam(key)]} readOnly={true} />
          <span className="value-suffix">{value.suffix}</span>
        </div>
      </div>
    );
  };

  renderRightPart() {
    var fields: Map<CUT_PARAM_TYPE, CutParam> = FRAISAGE_FIELDS;

    if (this.state.toolfinderSelection.machining?.usiGrpId == 100000) {
      fields = FRAISAGE_FIELDS;
    } else if (this.state.toolfinderSelection.machining?.usiGrpId == 100001) {
      fields = PERCAGE_FIELDS;
    } else {
      fields = FRAISAGE_FIELDS;
    }

    var editableFields: Map<CUT_PARAM_TYPE, CutParam> = new Map();
    var noEditableFields: Map<CUT_PARAM_TYPE, CutParam> = new Map();

    fields.forEach((value: CutParam, key: CUT_PARAM_TYPE) => {
      if (value.isEditable) {
        editableFields.set(key, value);
      } else {
        noEditableFields.set(key, value);
      }
    });

    var linesNumberTopColumn1: number = Math.ceil(editableFields.size / 2);
    var linesNumberTopColumn2: number = editableFields.size - linesNumberTopColumn1;
    var topLeftColumn: Array<any> = [];
    var topRightColumn: Array<any> = [];
    var index = 0;
    editableFields.forEach((value: CutParam, key: CUT_PARAM_TYPE) => {
      if (index < linesNumberTopColumn1) {
        topLeftColumn.push(this.renderCutParamItem(value, key));
      } else if (index >= linesNumberTopColumn2) {
        topRightColumn.push(this.renderCutParamItem(value, key));
      }
      index++;
    });

    var linesNumberBottomColumn1: number = Math.ceil(noEditableFields.size / 2);
    var linesNumberBottomColumn2: number = noEditableFields.size - linesNumberBottomColumn1;
    var bottomLeftColumn: Array<any> = [];
    var bottomRightColumn: Array<any> = [];
    var index = 0;
    noEditableFields.forEach((value: CutParam, key: CUT_PARAM_TYPE) => {
      if (index < linesNumberBottomColumn1) {
        bottomLeftColumn.push(this.renderCutParamItem(value, key));
      } else if (index >= linesNumberBottomColumn2) {
        bottomRightColumn.push(this.renderCutParamItem(value, key));
      }
      index++;
    });

    return (
      <div className="col-md-12 col-lg-7 dashboard-cut-params">
        <div className="row mb-4">
          <div className="col-md-12 col-lg-12 col-xl-6 d-flex align-items-center">
            <h3>{this.props.t("screen.dashboard.cut_params_title")}</h3>
          </div>
          <div className="col-md-12 col-lg-12 col-xl-6">
            {this.state.toolfinderSelection.article != undefined && (
              <div className="choosen-ref d-flex flex-row gap-3 align-items-center justify-content-between">
                <div className="choosen-ref-leftside">
                  <div className="choosen-ref-title">{this.props.t("screen.dashboard.choosen_ref")}</div>
                  <div className="choosen-ref-data">
                    {this.props.t("screen.dashboard.ref_abbreviation")} {this.state.toolfinderSelection.reference.code} ø{this.state.toolfinderSelection.article.dimD1}x{this.state.toolfinderSelection.article.dimL1}{" "}
                    {this.state.toolfinderSelection.coating.code}
                  </div>
                </div>
                <div className="choosen-ref-rightside flex-row gap-5">
                  <AddToCartButton article={this.state.toolfinderSelection.article} revCode={this.state.toolfinderSelection.coating.code} />
                  <AddToFavoriteButton article={this.state.toolfinderSelection.article} revCode={this.state.toolfinderSelection.coating.code} />
                </div>
              </div>
            )}
          </div>

          {/* {this.state.toolfinderSelection.machine == undefined && this.state.cutParamsValue.Vc != 0 ? (
          <div className="alert alert-warning mt-4 ref-warning" role="alert">
            <span className="icon icon-info icon-left"></span>
            {this.props.t("screen.dashboard.info_default_machine")}
          </div>
        ) : (
          <div></div>
        )} */}
        </div>

        <div className="row">
          <div className="col-md-12 col-lg-12 col-xl-6">{topLeftColumn}</div>

          <div className="col-md-12 col-lg-12 col-xl-6">{topRightColumn}</div>
        </div>
        <div className="row mt-4">
          <div className="col-md-6 col-lg-12 col-xl-6">{bottomLeftColumn}</div>

          <div className="col-md-6 col-lg-12 col-xl-6">{bottomRightColumn}</div>
        </div>

        <div className="mt-4 text-black-light  liveUpdateText">
          <span className="icon icon-loop2 me-3"></span>
          {this.props.t("screen.dashboard.live_update_cut_params")}
        </div>
      </div>
    );
  }

  render() {
    // var test = useContext(MaterialsBlocContext);
    return (
      <BaseScreen center={true} actionButtons={this.renderActionButtons()}>
        <Toaster
          containerStyle={{
            top: 120,
            right: 30,
          }}
        />

        {this.state.downloadMobileAppPopupClosed == false && isMobile == true ? (
          <div className="slideInUpMobile">
            <h5>{this.props.t("screen.dashboard.phone_popup.title")}</h5>
            <p>{this.props.t("screen.dashboard.phone_popup.message")}</p>
            <div className="actions d-flex">
              <a href="https://novadev.ch/toolfinder">{this.props.t("screen.dashboard.phone_popup.downloadButton")} →</a>
              <div className="later" onClick={this.closeDownloadMobileAppPopup}>
                {this.props.t("screen.dashboard.phone_popup.noThanksButton")}
              </div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        <div className="dashboard-screen w-100 d-flex flex-column align-items-center justify-content-center">
          {this.renderNews()}

          <div className="container ">


            <div className="row dashboard-content">
              {this.renderLeftPart()}
              {this.renderRightPart()}
            </div>

            <button
              className="btn btn-danger text-white mt-4 reset-button"
              onClick={(event) => {
                this.context.toolfinderSelectionBloc.add(new ToolfinderResetSelections());
                this.context.cutParamsBloc.add(new CutParamsReset());
              }}
            >
              <span className="icon icon-eraser"></span>
              {this.props.t("screen.dashboard.reset_selection_button")}
            </button>
          </div>

        </div>
      </BaseScreen>
    );
  }
}

export default withTranslation("common")(DashboardScreen);
